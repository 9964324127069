.container {
  padding: 0rem 2rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}

.wrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
}

.wrapper > img {
  width: 32rem;
  position: absolute;
  border-radius: 100%;
  bottom: 0;
}

.pinkCircle {
  width: 30rem;
  height: 30rem;
  border-radius: 46%;
  z-index: -99;
  position: absolute;
  bottom: 0;
  background: #ee9ca7; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #ffc8cf,
    #ee9ca7
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffc8cf,
    #ee9ca7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cart2 {
  position: absolute;
  bottom: 25%;
  right: 1%;
  display: flex;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.cart2 > svg {
  width: 30px;
  height: 30px;
  background: white;
  padding: 10px;
  border-radius: 50%;
  border: 5px solid var(--black);
}

.signup {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: white;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 15px;
  box-shadow: var(--shadow1);
}

.signup > :first-child {
  display: block;
  widows: 6rem;
}

.signup > :nth-child(2) {
  border-radius: 50%;
  border: 1px solid skyblue;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.h_sides {
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.text1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  width: min-content;
  display: block;
  font-weight: 600;
}

.text2 {
  display: flex;
  flex-direction: column;
  width: min-content;
  color: #000;
}

.text2 > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}

.text2 > :nth-child(2) {
  display: block;
}

.traffic {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.traffic > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}

.customers {
  display: flex;
  flex-direction: column;
  text-align: right;
}

.customers > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}

@media screen and (max-width: 856px) {
  .text1,
  .tetx2 > span:nth-of-type(2) {
    font-size: 0.9rem;
    text-align: justify;
  }

  .text2 > span:nth-of-type(1),
  .traffic > span:nth-child(1),
  .customers > span:nth-child(1) {
    font-size: 1.5rem;
    text-align: left;
  }

  .pinkCircle {
    position: absolute;
    width: 85%;
    height: 93%;
  }

  .wrapper > img {
    width: 100%;
    height: 80%;
  }

  .container {
    grid-template-areas:
      "left center center"
      "right right right";
  }

  .container > :first-child {
    grid-area: left;
    grid-template-columns: none;
    gap: 8rem;
  }

  .container > :nth-child(2) {
    grid-area: center;
  }

  .container > :nth-child(3) {
    grid-area: right;
    display: flex;
    justify-content: space-around;
    margin: 2rem 0rem;
  }
}

@media screen and (max-width: 640px) {
  .pinkCircle {
    top: 3.7%;
    width: 17rem;
    height: 56%;
  }

  .wrapper > img {
    top: 4.3rem;
    width: 20rem;
    height: 15rem;
  }

  .cart2 {
    transform: scale(0.7);
    right: 2px rem;
    bottom: 0;
  }
}
