@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Urbanist:wght@100;200;500;600;800&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  background: var(--bg);
  transition: all 3ms ease;
  color: var(--black);
  scroll-behavior: smooth;
}
